<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('oem-b2b.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container class="pb-0 mb-0">
              <v-row>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="oemb2b.tenant_id"
                    dense
                    :items="clientsList.filter((r) => r.product == 'oem')"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_oem_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemb2b.business_name"
                    dense
                    :label="$t('business_name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemb2b.business_email"
                    dense
                    :label="$t('business_email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemb2b.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemb2b.country_code"
                    dense
                    :items="countryCodeLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('country_code_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemb2b.business_phone"
                    dense
                    type="number"
                    :label="$t('phone_number_*')"
                    :rules="[
                      rules.required,
                      rules.startFrom(3),
                      rules.min_length(10),
                      rules.max_length(10),
                    ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemb2b.avg_vehicle_weight"
                    type="number"
                    dense
                    :label="$t('weight_capacity')"
                    :rules="[rules.float(2)]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-file-input
                    v-model="oemb2b.business_logo"
                    dense
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    :label="$t('business_logo_*')"
                    :rules="[rules.required, rules.file_size]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pt-0 mt-0">
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(oemb2b)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
// import axios from "axios";

// const base_url = process.env.VUE_APP_API_BASEURL;

export default {
  mixins: [validationRules],

  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      oemb2b: {},

      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      clientsList: "meta/clientsList",
    }),
  },

  async mounted() {
    if (this.authUser.isAdmin) {
      await this.$store.dispatch("meta/getClients", "tenant");
    }
  },
  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(oemB2b) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        // oemb2b.tenant_id = this.authUser.id;
        await this.$store
          .dispatch("oemBusiness/add", oemB2b)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
